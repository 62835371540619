:root {
    --name-fontsize: 20px;
    --value-fontsize: 20px;
    --unit-fontsize: 12px;

    --parm-color-hr: #01b701;
    --parm-color-spo2: #ffff00;
    --parm-color-resp: #00ffff;
    --parm-color-ibp1: #d811b7;

    --parameterbox-width: 130px;
}

.liveUnit {
    padding: 3px;
}
.monitorHeader {
    background-color: #004ba1;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.connectionOK {
    color: #3af900;
}

.connectionFail {
    color: #fe2f2f;
}
.parameterBox {
    background-color: black;
    width: var(--parameterbox-width);
    color: white;
    padding: 5px;
    max-height: 123px;
    border: 0px solid grey;
}

.parameter-name-HR {
    color: var(--parm-color-hr);
    font-size: var(--name-fontsize);
}
.parameter-value-HR {
    color: var(--parm-color-hr);
    font-size: var(--value-fontsize);
}
.parameter-unit-HR {
    color: var(--parm-color-hr);
    font-size: var(--unit-fontsize);
}

.parameter-name-SpO2 {
    color: var(--parm-color-spo2);
    font-size: var(--name-fontsize);
}
.parameter-value-SpO2 {
    color: var(--parm-color-spo2);
    font-size: var(--value-fontsize);
}
.parameter-unit-SpO2 {
    color: var(--parm-color-spo2);
    font-size: var(--unit-fontsize);
}

.parameter-name-Resp {
    color: var(--parm-color-resp);
    font-size: var(--name-fontsize);
}
.parameter-value-Resp {
    color: var(--parm-color-resp);
    font-size: var(--value-fontsize);
}
.parameter-unit-Resp {
    color: var(--parm-color-resp);
    font-size: var(--unit-fontsize);
}

.parameter-name-IBP1 {
    color: var(--parm-color-ibp1);
    font-size: var(--name-fontsize);
}
.parameter-value-IBP1 {
    color: var(--parm-color-ibp1);
    font-size: var(--value-fontsize);
}
.parameter-unit-IBP1 {
    color: var(--parm-color-ibp1);
    font-size: var(--unit-fontsize);
}

.parameter-name-disconnected {
    color: white;
    font-size: var(--name-fontsize);
}

.parameter-unit-disconnected {
    color: white;
    font-size: var(--unit-fontsize);
}

.parameter-value-disconnected {
    color: white;
    font-size: var(--value-fontsize);
}

.waveformBox {
    border-right: 1px solid white;
}

.parameter-box-normal {
    border: 1px solid white;
}

.parameter-box-alarm {
    border: 4px #ff0000 solid;
    animation: blink 1s;
    animation-iteration-count: infinite;
}

.parameter-value-normal {
    border: 2px solid black;
}

.parameter-value-alarm {
    border: 2px #ff0000 solid;
    animation: blink 1s;
    animation-iteration-count: infinite;
}
@keyframes blink {
    50% {
        border-color: #000;
    }
}
